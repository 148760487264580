import React from 'react'
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import { Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterCompact from '@widgets/NewsletterCompact/NewsletterCompact'

export default ({data}) => (
  <>
    <Seo title='Page non trouvée' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header="Oups ! La page que vous avez demandée n'existe pas..."
            subheader="Vous pouvez profiter de l'occasion pour augmenter votre rendement de 10%..."
          />
        </Section>
        <NewsletterCompact newsletterInfo={data.landingPageInfo.newsletterBox} simple/> 
        <Section>
        <Divider />
          <Button variant='primary' as={Link} to='/'>
            Retourner à la page d'accueil
          </Button>
        </Section>
      </Main>
    </Stack>
  </>
)

export const query = graphql`
  query {
    landingPageInfo: contentfulLandingPage {
      newsletterBox{
        buttonTitle
        description
        headerTitle
        picture {
          fixed(width: 300) {
            src
          }
        }
      }
    }

  }
`